<template>
	<transition name="page" mode="out-in">
		<header v-if="headingImage" ref="header" :class="{ subpage: !route.name.startsWith('index') }">
			<div class="hero-image">
				<div class="hero-image-container">
					<slot name="hero-content" />
					<picture>
						<source
							v-if="headingImage.imagePortrait && headingImage.imagePortraitWebp"
							:srcset="headingImage.imagePortraitWebp"
							media="(max-width: 500px)"
							type="image/webp"
						/>
						<source
							v-if="headingImage.imagePortrait"
							:srcset="headingImage.imagePortrait"
							media="(max-width: 500px)"
						/>
						<source v-if="headingImage.imageWebp" :srcset="headingImage.imageWebp" type="image/webp" />
						<source :srcset="headingImage.image" />
						<img class="image" :src="headingImage.image" :alt="headingImage.imageAlt" />
					</picture>
				</div>
			</div>
			<booking-form v-if="route.name.startsWith('index')" :booking-form="defaults[locale].homepage" />
		</header>
	</transition>
</template>

<script setup>
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();
const route = useRoute();
const header = ref(null);

await fetchDefaults();

defineProps({
	headingImage: { type: Object, default: () => {} },
});
</script>

<style lang="scss" scoped>
header {
	position: relative;
	overflow: hidden;
	z-index: 1;
}

.hero-image,
.hero-image .hero-image-container {
	height: 100vh;
	position: relative;
}

.subpage .hero-image,
.subpage .hero-image .hero-image-container {
	height: 60vh;
}

.hero-image-container::after {
	content: '';
	position: absolute;
	inset: 0;
	z-index: 1;
	background: rgb(0 0 0 / 20%);
}

.image {
	height: 100%;
	width: 100%;
	object-fit: cover;
	animation: fade-in 800ms ease-out;
}

.hero-content {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 250px;
	color: #fff;
	z-index: 2;

	h2 {
		font-size: 90px;
		line-height: 1.3em;
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@media (max-width: 960px) {
	.hero-content {
		h2 {
			font-size: 60px;
		}
	}
}

@media (max-width: 720px) {
	.hero-image .hero-image-container {
		transform: none !important;
	}

	.hero-content {
		bottom: 40%;

		h2 {
			font-size: 44px;
		}
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
	.hero-image,
	.hero-image .hero-image-container {
		height: 480px;
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
	.hero-image,
	.hero-image .hero-image-container {
		height: 568px;
	}
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
	.hero-image,
	.hero-image .hero-image-container {
		height: 667px;
	}
}

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
	.hero-image,
	.hero-image .hero-image-container {
		height: 736px;
	}
}
</style>
