<template>
	<div ref="qrPanel" class="qr-panel">
		<div v-if="bookingForm.benefitsContent" class="row benefits-row">
			<ul>
				<li>
					<strong class="benefits-header">{{ bookingForm.benefitsContent }}</strong>
				</li>
				<li v-for="(benefit, index) in bookingForm.benefit" :key="index">
					<font-awesome-icon :icon="`far fa-check-circle`" size="1x" />
					<strong>{{ benefit.content }}</strong>
				</li>
			</ul>
		</div>
		<div class="row">
			<client-only>
				<div class="columns">
					<div class="qr-container booking bookForm">
						<div class="qr-section">
							<label>
								{{ $t('checkin') }}
								<font-awesome-icon :icon="`far fa-chevron-down`" size="1x" />
							</label>
							<date-picker
								v-model:value="state.arrival"
								:clearable="false"
								:disabled-date="disableArrivalDates"
								value-type="format"
								format="DD-MM-YYYY"
								:placeholder="$t('formArrival')"
								class=""
								:lang="datepickerLang"
								@pick="pickArrival"
							>
								<template #icon-calendar> &nbsp; </template>
							</date-picker>
						</div>
						<div class="qr-section">
							<label>
								{{ $t('checkout') }}
								<font-awesome-icon :icon="`far fa-chevron-down`" size="1x" />
							</label>
							<date-picker
								v-model:value="state.departure"
								:open="state.openDeparture"
								:disabled-date="disableDepartureDates"
								value-type="format"
								format="DD-MM-YYYY"
								:placeholder="$t('formDeparture')"
								:default-value="state.pickedArrivalDate ?? new Date()"
								class=""
								:lang="datepickerLang"
								@open="state.openDeparture = true"
								@close="state.openDeparture = false"
							>
								<template #icon-calendar> &nbsp; </template>
							</date-picker>
						</div>
						<book-button class="qr-section button" :arrival="state.arrival" :departure="state.departure">
							{{ $t('checkAvailability') }}
						</book-button>
					</div>
				</div>
			</client-only>
		</div>
	</div>
</template>

<script setup>
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import datePickerTranslations from '~/config/datePicker.js';

const { locale } = useI18n();

defineProps({
	bookingForm: { type: Object, default: () => ({}) },
});

const state = reactive({
	arrival: null,
	departure: null,
	openDeparture: false,
	pickedArrivalDate: null,
});

const qrPanel = ref(null);

const datepickerLang = computed(() => datePickerTranslations[locale] ?? datePickerTranslations.en);

const pickArrival = (date) => {
	state.departure = '';
	state.pickedArrivalDate = date;
	state.openDeparture = true;
};

const disableArrivalDates = (date) => {
	const today = new Date();

	return date < today.setDate(today.getDate() - 1);
};

const disableDepartureDates = (date) => {
	if (!state.pickedArrivalDate) {
		return disableArrivalDates(date);
	}

	return date <= state.pickedArrivalDate;
};

onMounted(() => {
	qrPanel.value.classList.add('mounted');
});
</script>

<style lang="scss">
.mx-calendar-content .cell.active {
	background-color: var(--primary-color);
}
</style>

<style lang="scss" scoped>
.qr-panel {
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 2;
	margin-bottom: -100px;
	opacity: 0;
	transition: all 0.8s ease-out;

	&.mounted {
		margin-bottom: 0;
		opacity: 1;
	}

	.columns:first-child {
		background: var(--primary-background-color);
		padding-right: 0;
	}

	&::before {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		height: 101px;
		left: -100%;
		right: 60%;
		background: var(--primary-background-color);
	}
}

.benefits-row ul {
	display: flex;
	flex-flow: row nowrap;
	list-style: none;
	color: #fff;
	padding: 0 0 15px 50px;
	font-size: 14px;
}

.benefits-row li {
	margin: 0 20px 0 0;
	letter-spacing: 1px;
}

.qr-container {
	display: flex;
}

.qr-section {
	float: left;
	padding: 20px;
	min-width: 170px;
	max-width: 270px;
	position: relative;

	label {
		text-transform: uppercase;
		color: #a8a6a7;
		float: left;
		position: absolute;
		top: 25px;
		left: 20px;
		right: 0;
		font-size: 13px;
		letter-spacing: 2px;
	}

	:deep(input:not(.button)),
	:deep(select) {
		appearance: none;
		border: none;
		box-shadow: none;
		color: var(--primary-text-color);
		font-size: 22px;
		font-family: var(--heading-font-family);
		font-weight: 300;
		background: none;
		position: relative;
		z-index: 1;
		cursor: pointer;
		padding: 30px 0 0;
		height: auto;

		&::placeholder {
			color: var(--primary-text-color);
		}

		&:focus {
			outline: #fff;
		}
	}

	&.button {
		padding: 30px 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 270px;
	}
}

.qr-panel .right a {
	margin: 40px 0 0;
	float: right;
}

@media (max-width: 1280px) {
	.qr-section {
		padding: 20px 10px;
		max-width: 260px;

		label {
			left: 10px;
		}
	}
}

@media (max-width: 1080px) {
	.qr-section {
		padding: 20px 8px;
		max-width: 225px;
	}
}

@media (max-width: 960px) {
	.qr-panel {
		display: none;
	}
}
</style>
